<template>
  <section class="container bid-form-section">
    <h2 class="mb-40 mx-70 mw-1100 t-blackDark">{{ title }}</h2>
    <el-form
      ref="bidForm"
      :model="form"
      :rules="rules"
      label-position="top"
      class="bid-form i-components mx-70 mb-60 df f-wrap"
      @keyup.enter.native="onSend"
    >
      <el-form-item prop="fio" label="Фамилия и имя" class="big">
        <el-input v-model="form.fio" autocomplete="nope" />
      </el-form-item>
      <el-form-item prop="phone" label="Телефон">
        <el-input
          v-model="form.phone"
          v-mask="'+7 (###) ###-##-##'"
          autocomplete="nope"
        />
      </el-form-item>
      <el-form-item prop="inn" label="ИНН организации" class="big">
        <el-input v-model="form.inn" autocomplete="nope" />
      </el-form-item>
      <el-form-item prop="company_name" label="Название организации">
        <el-input v-model="form.company_name" autocomplete="nope" />
      </el-form-item>
      <div class="pseudo">
        <el-form-item
          v-if="carrier"
          prop="job_format"
          label="Формат вашей работы"
          class="big"
        >
          <el-select v-model="form.job_format">
            <el-option
              v-for="item in ['Собственник транспорта', 'Диспетчер']"
              :label="item"
              :value="item"
              :key="item"
            />
          </el-select>
        </el-form-item>
      </div>
      <el-form-item class="ta-r send-btn">
        <i-button
          text="ОТПРАВИТЬ"
          width="250"
          :loading="isLoading"
          :disabled="isLoading"
          @click="onSend"
        />
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import IButton from '@/components/Ui/Button'
import ApiFactory from '@/api/ApiFactory'
import { bidFormRules } from './constants'

const ContactCarriers = ApiFactory.get('contactCarriers')
const ContactCustomers = ApiFactory.get('contactCustomers')

export default {
  name: 'BidForm',
  components: { IButton },
  props: {
    carrier: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLoading: false,
      form: {},
      rules: bidFormRules
    }
  },
  methods: {
    onSend() {
      this.$refs.bidForm.validate(valid => {
        if (valid) {
          this.isLoading = true
          if (this.carrier)
            ContactCarriers.createItem(this.form)
              .then(() => {
                this.$message.success('Заявка успешно отправлена!')
                this.$refs.bidForm.resetFields()
              })
              .catch(err => this.errHandler(err))
              .finally(() => (this.isLoading = false))
          else
            ContactCustomers.createItem(this.form)
              .then(() => {
                this.$message.success('Заявка успешно отправлена!')
                this.$refs.bidForm.resetFields()
              })
              .catch(err => this.errHandler(err))
              .finally(() => (this.isLoading = false))
        }
      })
    }
  }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

.bid-form-section
  h2
    margin-top: 70px
    @include toH(700px)
      margin-top: 30px
  .mw-1100
    max-width: 1100px
  .bid-form
    @include to(768px)
      padding: 0 20px
    .pseudo
      min-width: 525px
      @include to(1100px)
        min-width: auto
    .el-form-item
      width: 380px
      @include to(1100px)
        width: 475px
      @include to(768px)
        width: 100%
      &.big
        width: 475px
        margin-right: 50px
        @include to(1100px)
          margin-right: 0
        @include to(768px)
          width: 100%
      &.send-btn
        margin-top: 48px
</style>
