<template>
  <bg-image-wrapper src="bg-for-customer.jpeg">
    <section slot="body" class="fw-l t-black-light fs-18">
      <h2 class="fs-22 t-blackDark tt-up mb-0">
        Вам нужен транспорт — мы сделаем это дешевле!
      </h2>
      <p class="mt-0">
        Вы теряете деньги в непрозрачных торгах, если вы еще не используете
        тендерную систему выбора исполнителя на организацию доставки вашего
        груза.
      </p>
      <h2 class="fs-22 t-blackDark mb-0">ЭТО БЕСПЛАТНО!</h2>
      <p class="mt-0">
        Мы предоставляем наш сервис абсолютно бесплатно и помогает экономить на
        ваших перевозках
      </p>
      <h2 class="fs-22 t-blackDark tt-up mb-0">
        Вы уже используете торги в своей работе?
      </h2>
      <p class="mt-0">
        Попробуйте наш сервис, если не понравится — мы возьмем в работу все ваши
        пожелания бесплатно. Наша цель: сделать идеальную тендерную площадку.
      </p>
    </section>
    <bid-form
      title="ЕСЛИ ВАС ЗАИНТЕРЕСОВАЛ СЕРВИС, ЗАПОЛНИТЕ ФОРМУ И МЫ ПРОВЕДЕМ ДЛЯ
       ВАС ПОЛНОЦЕННУЮ ПРЕЗЕНТАЦИЮ"
    />
  </bg-image-wrapper>
</template>

<script>
import BgImageWrapper from '@/components/Wrappers/BgImageWrapper'
import BidForm from '@/components/TablesFormsFilters/BidForm'

export default {
  name: 'ForCustomers',
  components: { BgImageWrapper, BidForm }
}
</script>
